/* eslint-disable no-extra-boolean-cast */
/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { Dialog } from "@headlessui/react";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { QrReader } from "react-qr-reader";
import RoundedButton from "src/Components/Buttons/RoundedButton";

// TODO: replace QR lib with one that can handle a 4k+ char QR code
const View = () => (
  <img
    src="/images/qrviewfinder.png"
    alt="viewfinder"
    className="absolute top-0 bottom-0 left-0 right-0 z-30 w-11/12 h-auto p-3 m-auto"
  />
);

const ScanQR = ({ ...props }) => {
  const [error, setError] = useState({
    title: "",
    message: "",
  });

  return (
    <div className="flex flex-col justify-center w-full h-full m-auto 4xl:w-4/12 3xl:w-5/12 2xl:w-6/12 xl:w-7/12 lg:w-8/12">
      <div className="relative flex flex-col justify-center w-full h-screen mx-auto text-center">
        {!error.message ? (
          <>
            <QrReader
              ViewFinder={View}
              containerStyle={{
                position: "relative",
                padding: "0 0 0 0",
                display: "flex",
                justifyContent: "center",
                margin: "auto",
                height: "100%",
                width: "100%",
              }}
              videoStyle={{
                zIndex: 1,
                height: "auto",
                width: "auto",
                margin: "auto",
                position: "absolute",
                right: "0",
                top: "0",
                left: "0",
                bottom: "0",
              }}
              videoContainerStyle={{
                position: "relative",
                height: "100%",
                width: "100%",
                margin: "auto",
                display: "flex",
                justifyContent: "center",
                padding: "0 0 0 0",
              }}
              constraints={{ facingMode: "environment" }}
              onResult={(result, err) => {
                if (result) {
                  props.setQr(result?.text);
                  props.setOpen(false);
                }
                if (err) {
                  setError({ error: error.title, message: error.message });
                }
              }}
            />
            <RoundedButton
              className="absolute left-0 right-0 z-50 w-8/12 py-3 mx-auto mt-4 text-base text-black bg-yellow-500 sm:w-30 bottom-12"
              onClick={() => props.setOpen(false)}
            >
              Close
            </RoundedButton>
          </>
        ) : (
          <>
            <div className="">
              <div className="mt-3 text-center">
                <div className="m-auto">
                  <div className="flex items-center justify-center flex-shrink-0 w-12 h-12 mx-auto mb-6 bg-red-100 rounded-full dark:bg-newdarkmode-700">
                    <ExclamationTriangleIcon
                      className="w-8 h-8 text-red-600 dark:text-yellow-500"
                      aria-hidden="true"
                    />
                  </div>
                  <Dialog.Title
                    as="h3"
                    className="mx-4 my-auto text-lg font-medium text-black leading-6 dark:text-gray-300"
                  >
                    {props.title}
                  </Dialog.Title>
                </div>
                <div className="mt-4">
                  <p className="text-base text-black dark:text-gray-300">
                    {props.message}
                  </p>
                </div>
              </div>
            </div>
            <div className="w-10/12 mx-auto mt-5 sm:w-5/12">
              <RoundedButton
                className="inline-flex justify-center w-full px-4 mt-3 text-base font-medium text-black bg-yellow-500 rounded-md shadow-sm"
                onClick={() => {
                  props.setOpen(false);
                }}
              >
                Okay
              </RoundedButton>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ScanQR;
